import React from 'react'


import './footer.css'
import { Link } from 'react-router-dom'
import { colorBlack, colorGray, colorWhite } from '../data/color'

const FooterComponent = (props) => {
  return (
    <>
    <div className = "footerWrapper" style = {{backgroundColor : props.type ==="1" ? colorBlack : colorWhite}}>
      <div  style = {{textAlign:"center", height : "80%", color : props.type ==="1" ? colorWhite : colorBlack}}>
        <a href="#" className="back-to-top">
            <div className="arrow"></div>
        </a>
        <div className = "backToTopText">Back to Top</div>
      </div>
      <div style = {{textAlign:"center", verticalAlign: "bottom", color : colorGray}}>
      © 2023 by AGORA Entertainment Ltd.
      </div>
    </div>
    <footer className = "footerBarWrapper" style={{ background: '#2C3862', width : "100%", height : "12vh"}}>
      <div className = "footerbar">
        <ul className='footerLinks'>
          <li className='footerLi'><Link to = '/'>Home</Link></li>
          <li className='footerLi'><Link to = '/aboutus'>About Us</Link></li>
          <li className='footerLi'><Link to = '/?instagram'>Instagram</Link></li>
          <li className='footerLi'><Link to = '/?contact'>Contact Us</Link></li>
          <li className='footerLi'><Link to = '/menu'>Menu</Link></li>
          <li className='footerLi'><Link to = '/happyhour'>Happy Hour</Link></li>
          <li className='footerLi'>
            <div  style = {{display : "inline", color : "white"}} onClick = {() => {window.open("https://www.facebook.com/CocoRicoCafe", "_blank")}}>
              <i class="fa-brands fa-square-facebook icons" style = {{marginRight : "1vw", fontSize : "25px"}}/>
            </div>
            <div className = "iconWrapper"  style = {{display : "inline", color : "white"}} onClick = {() => {window.open("https://www.instagram.com/cocoricocafe/", "_blank")}}>
              <i class="fa-brands fa-instagram icons" style = {{marginLeft : "1vw", fontSize : "25px"}}/>
            </div>
          </li>
        </ul>
      </div>
    </footer>
    </>
  )
}

export default FooterComponent