import React, { useEffect, useState } from 'react'
import './Aboutus.css'
import CustomNavbar from '../layouts/CustomNavbar'
import FooterComponent from '../layouts/FooterComponent'
import { Col, Container, Row } from 'react-bootstrap'
import aboutus1 from '../Images/aboutus1.jpg'
import aboutus1_mobile from '../Images/aboutus_1_mobile.png'
import aboutus2 from '../Images/aboutus2.png'
import aboutus_3_1 from '../Images/aboutus_3_1.png'
import aboutus_3_2 from '../Images/aboutus_3_2.png'
import aboutus_3_3 from '../Images/aboutus_3_3.png'
const Aboutus = () => {
  
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);

  useEffect(()=> {
      document.getElementById('top').scrollIntoView({behavior: 'smooth' });
  }, [])

  useEffect(() => {
      // Update the isSmallScreen state on window resize
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 500);
      };

      window.addEventListener('resize', handleResize);

      // Clean up the event listener
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (

     <div className='wrap'>
      <div className="main_au" id = "top">
      <CustomNavbar/>
        <div className = "menuTitle">About Us</div>
      </div>

      
      <div className="aboutus1" id = "top">
        <Container className='align-items-center happyhourWrapper' style = {{background: "transparent"}}>
          <Row style = {{height : "100%"}}>
            <Col xs = {{span : 12}} lg = {{span : 6}} className='align-items-center'>
              <div className = "contentsWrapper_au">
                <div className="rectangle"></div>
                <div className= 'subtitle'>Founded in 2004</div>
                <div className= 'contents'>
                Coco Rico Café, founded in 2004, is a beloved local hotspot located on Robson Street in the heart of Downtown Vancouver. With a lively atmosphere and a dedicated community of regulars, Coco Rico Café offers a relaxed, uncomplicated atmosphere complimenting the menu which consists of wide array of dishes that let the natural flavours shine through.
              </div>
              <div className= 'contents'>
              Join us to enjoy fresh food made from daily delivered ingredients and special cocktails made by our talented professional bartenders. Have an unforgettable experience that embodies the spirit of Vancouver's Downtown pub scene.
              </div>
              </div>
            </Col>
            <Col xs = {{span : 12}} lg = {{span : 6}} className='align-items-center' style = {{textAlign: "center"}}>
              <img className = "instaPics" style = {{width : "90%", marginBottom: "5px"}}  src={isSmallScreen? aboutus1_mobile : aboutus1} alt ="aboutus1"/>
            </Col>
          </Row>
        </Container>
      </div>


      <div className="aboutus2" id = "top">
        <Container className='align-items-center happyhourWrapper' style = {{background: "transparent"}}>
          <Row>
            <Col xs = {{span : 12}} lg = {{offset : 6, span : 6}} className='align-items-center'>
              <div className = "contentsWrapper_au">
                <div className="rectangle"></div>
                <div className= 'subtitle'>Get Delivered Everyday</div>
                <div className= 'contents'>
                At our restaurant, we take pride in serving fresh and delicious food made with the finest ingredients. Every weekday, we receive a morning delivery of all our ingredients to ensure the highest quality and freshness. Our Benedict and homemade burgers are particularly renowned among our customers.               </div>
              <div className= 'contents'>
                We strive to provide you with a memorable dining experience by offering a wide range of dishes prepared with utmost care and using only the freshest ingredients.
              </div>
              </div>
            </Col>
          </Row>
          {
            isSmallScreen && (
            <Row>
              <Col xs = {{span : 12}} lg = {{offset : 6, span : 6}} className='align-items-center' style = {{textAlign: "center"}}>
                <img className = "instaPics" style = {{width : "90%", marginBottom: "15px"}} src={aboutus2} alt ="photo1"/>
              </Col>
            </Row>
            )
          }
          
        </Container>
      </div>

      <div className="aboutus3">
        <Container className='align-items-center' style = {{background: "transparent"}}>
          <Row>
            <Col xs = {{span : 12}} lg = {{span : 12}} className='align-items-center'>
              <div className = "contentsWrapper_au">
                <div className="rectangle"></div>
                <div className= 'subtitle'>Enjoy Beer & Cocktail with Coco Rico Café</div>
                <div className= 'contents'>
                Whether you're a visitor exploring the area or a neighbor looking for a friendly gathering spot, our restaurant is the perfect destination. From our extensive selection of beverages to our delicious restaurant fare, we guarantee a memorable experience that will keep you coming back for more.</div>
              </div>
            </Col>
          </Row>
          <Row style ={{ margin: "auto"}}>
            <Col lg = {4} xs = {4} style= {{textAlign:"center"}}>
            <img className = "instaPics" style = {{width : "100%", marginBottom: "15px"}} src={aboutus_3_1} alt ="photo1"/>
            </Col>
            <Col lg = {4} xs = {4} style= {{textAlign:"center"}}>
              <img className = "instaPics"  style = {{width : "100%", marginBottom: "5px"}} src={aboutus_3_2} alt ="photo2"/>
            </Col>
            <Col lg = {4} xs = {4} style= {{textAlign:"center"}}>
            <img className = "instaPics"   style = {{width : "100%", marginBottom: "5px"}} src={aboutus_3_3} alt ="photo3"/>
            </Col>
          </Row>
        </Container>
      </div>

      
      
      <FooterComponent type = "1"/>
    </div>
  )
}

export default Aboutus