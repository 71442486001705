import React, { useEffect } from 'react'
import CustomNavbar from '../layouts/CustomNavbar'
import TabbedMenu from '../Components/TabbedMenu'
import FooterComponent from '../layouts/FooterComponent'
import './menu.css'
import { useLocation } from 'react-router-dom'

const Menu = () => {
  const location = useLocation();
  useEffect(()=> {
      document.getElementById('top').scrollIntoView({behavior: 'smooth'});    
  }, [location])

  return (
    <>
      <div className="mainMenu"  id = "top">
          <CustomNavbar></CustomNavbar>
          <div className = "menuTitle">Menu</div>
          
      </div>
      <TabbedMenu style = {{heigth : "100%"}}/>
      <FooterComponent/>
    </>
  )
}

export default Menu