import React, { useEffect } from 'react'
import CustomNavbar from '../layouts/CustomNavbar'
import TabbedMenu from '../Components/TabbedMenu'
import MainmenuComponent from '../Components/MainmenuComponent'
import HappyHourComponent from '../Components/HappyHourComponent'
import FooterComponent from '../layouts/FooterComponent'
import { useLocation } from 'react-router-dom'
import './menu.css'

const HappyHour = () => {
  
  const location = useLocation();
  useEffect(()=> {
    if(location.search ==='?aboutus') {
      document.getElementById('aboutus').scrollIntoView({behavior: 'smooth' });
    }else if(location.search === '?contact') {
      document.getElementById('contact').scrollIntoView({behavior: 'smooth' });
    }else if(location.search === '?instagram') {
      document.getElementById('instagram').scrollIntoView({behavior: 'smooth' });
    }else {
      document.getElementById('top').scrollIntoView({behavior: 'smooth' });
    }
  }, [location])

  return (
    <>
      <div className="mainMenu" id = "top">
          <CustomNavbar ></CustomNavbar>
          <div  className = "menuTitle">Happy Hour</div>
          
      </div>
      <HappyHourComponent/>
      <FooterComponent/>
    </>
  )
}

export default HappyHour