import React from 'react'
import {Tab, Tabs } from 'react-bootstrap'
import MainmenuComponent from './MainmenuComponent'
import BrunchComponent from './BrunchComponent'
import DrinkComponent from './DrinkComponent'
import './TabbedMenu.css'
const TabbedMenu = () => {
  return (
    <Tabs
      defaultActiveKey="mainmenu"
      id="justify-tab-example"
      className="mb-3 customTabs"
      justify
      style= {{marginBottom : "30px"}}
    >
      <Tab eventKey="mainmenu" title="Main Menu"  className= "customTab marginBtm">
        <MainmenuComponent/>
      </Tab>
      <Tab eventKey="brunch" title="Brunch" className= "customTab marginBtm">
        <BrunchComponent/>
      </Tab>
      <Tab eventKey="drink" title="Drink" className= "customTab marginBtm">
        <DrinkComponent/>
      </Tab>
    </Tabs>

  )
}

export default TabbedMenu