import React from 'react';

function TimeAgo({ timestamp }) {
  const getTimeAgo = (timestamp) => {
    const currentTime = new Date();
    const parsedTime = new Date(timestamp);

    const timeDifference = Math.abs(currentTime - parsedTime) / 1000;

    const years = Math.floor(timeDifference / 31536000);
    if (years >= 1) {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    }

    const months = Math.floor(timeDifference / 2592000);
    if (months >= 1) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    }

    const days = Math.floor(timeDifference / 86400);
    if (days >= 1) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    }

    const hours = Math.floor(timeDifference / 3600);
    if (hours >= 1) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    }

    const minutes = Math.floor(timeDifference / 60);
    if (minutes >= 1) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    }

    return 'Just now';
  };

  return <div>{getTimeAgo(timestamp)}</div>;
}

export default TimeAgo;
