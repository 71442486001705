import React, { useEffect, useState } from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import { drinkData } from '../data/mainmenuData'
const DrinkComponent = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);
    useEffect(() => {
        // Update the isSmallScreen state on window resize
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 500);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return (
    <Container style ={{backgroundColor : "white", width :"100%", height : "100%", marginTop : "60px"}}>
        
        <Row>
            <Col xs = {6} lg = {{offset : 1, span : 8}}>
                <div className = 'generalMenuTitle'>Draft Beer</div>
            </Col>
            <Col xs = {3}lg = {{span : 1}} style={{display: "flex", alignItems: "center", textAlign : "right"}}>
                <div className = "singleMenuTitle" style = {{textAlign : "right", width : "100%"}}>Sleeve</div>
            </Col>
            <Col xs = {3}lg = {{span : 1}} style={{display: "flex", alignItems: "center", textAlign : "right"}}>
                <div className = "singleMenuTitle" style = {{textAlign : "right", width : "100%"}}>Pitcher</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Draft Beer") {
                    if (!isSmallScreen) {
                        return <>
                            <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                <Col xs = {{span: 6}}  lg = {{offset : 1, span : 8}} style = {{paddingRight :"0px"}}>
                                    <div className = 'singleMenuTitle'>
                                        {item.menu} {item.comment}
                                    </div>
                                </Col>
                                <Col xs = {{span: 3}}  lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                    {`$${item.sleevePrice.toFixed(2)}`}
                                </div>
                                </Col>

                                <Col xs = {{span: 3}}  lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                    {`$${item.pitcherPrice.toFixed(2)}`}
                                </div>
                                </Col>
                            </Row>
                        </>
                    }else {
                        return <>
                            <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                <Col xs = {{span: 6}}  lg = {{offset : 1, span : 8}} style = {{paddingRight :"0px"}}>
                                    <div className = 'singleMenuTitle'>
                                        {item.menu}
                                    </div>
                                </Col>
                                <Col xs = {{span: 3}}  lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                    {`$${item.sleevePrice.toFixed(2)}`}
                                </div>
                                </Col>

                                <Col xs = {{span: 3}}  lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                    {`$${item.pitcherPrice.toFixed(2)}`}
                                </div>
                                </Col>
                            </Row>
                        </>
                    }



               
                }else {
                    return null;
                }
            })
        }

        
        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Cider & Coolers</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Cider & Coolers") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}  
                            </div>     
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                }
            })
        }
        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Highballs</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Highballs") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}}  lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}  
                            </div>     
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Bottles</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Bottles") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}  
                            </div>           
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        
        <Row>
            <Col xs = {6} lg = {{offset : 1, span : 8}}>
                <div className = 'generalMenuTitle mgTop60'>Wine</div>
            </Col>
            <Col xs = {3} lg = {{span : 1}} style={{display: "flex", alignItems: "center", textAlign : "right"}}>
                <div className = "singleMenuTitle mgTop60" style = {{textAlign : "right", width : "100%"}}>1/2 Litre</div>
            </Col>
            <Col  xs = {3} lg = {{span : 1}} style={{display: "flex", alignItems: "center", textAlign : "right"}}>
                <div className = "singleMenuTitle mgTop60" style = {{textAlign : "right", width : "100%"}}>Glass</div>
            </Col>
        </Row>
        <Row>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>

            
            
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Wine") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span:6}}  lg = {{offset : 1, span : 8}} style = {{paddingRight :"0px"}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col  xs = {{span: 3}}  lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item['1/2 Litre'].toFixed(2)}`}
                        </div>     
                        </Col>

                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.Glass.toFixed(2)}`}
                        </div>
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        
        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Non Alcoholic</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Non Alcoholic") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}}  lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}  
                            </div>         
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Shooters</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Shooters") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}  
                            </div>          
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }


        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Cocktails</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Cocktails") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}  
                            </div>          
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Martinis</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            drinkData.map((item) =>  {
                if(item.subCategory === "Martinis") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}  
                            </div>           
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                }
            })
        }
    </Container>
  )
}

export default DrinkComponent