import React, { useEffect, useRef } from 'react';
import { useLoadScript } from '@react-google-maps/api';

// 지도 컨테이너 스타일
const containerStyle = {
  width: '100%',
  height: '350px',
};

// 기본 위치 설정
const center = {
  lat: 49.2869,
  lng: -123.12863,
};

const position = {
    lat: 49.286923335722825,
    lng: -123.12858763187394 ,
};

// 환경 변수에서 API 키 가져오기
const google_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Maps = () => {
  // Google Maps API 비동기 로드
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: google_api_key,
  });

  // ref create to store map and marker
  const mapRef = useRef(null);

  useEffect(() => {
    if (isLoaded && window.google) {
      // Map Object create
      const map = new window.google.maps.Map(mapRef.current, {
        center,
        zoom: 18,
      });

      // Marker Object create
      new window.google.maps.Marker({
        position: position,
        map: map,
        title: 'CocoRico Cafe',
      });
    }
  }, [isLoaded]);

  if (loadError) {
    return <div>Error loading map</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return <div ref={mapRef} style={containerStyle}></div>;
};

export default Maps;
