import React from 'react'
import { Col, Container, Row} from 'react-bootstrap'
import { brunchData } from '../data/mainmenuData'
const BrunchComponent = () => {
  return (
    <Container style ={{backgroundColor : "white", width :"100%", height : "100%", marginTop : "60px"}}>
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle'>Brunch</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            brunchData.map((item) =>  {
                if(item.subCategory === "Brunch") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col  xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                            <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>     
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        
        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Drinks</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            brunchData.map((item) =>  {
                if(item.subCategory === "Drinks") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                            <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>   
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Extra</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            brunchData.map((item) =>  {
                if(item.subCategory === "Extra") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                            <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>   
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                }
            })
        }

        
        
    </Container>
  )
}

export default BrunchComponent