import React, { useState, useEffect } from 'react';
import './CustomNavbar.css';
import { Link } from 'react-router-dom';
const CustomNavbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [navbarScrolled, setNavbarScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);
  const handleToggleBtn = () => {
    setIsOpen(!isOpen)
    
  }
  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 80; // Adjust this value to change the scroll threshold

      if (scrollPosition > scrollThreshold && !navbarScrolled && !isSmallScreen) {
        setNavbarScrolled(true);
      } else if (scrollPosition <= scrollThreshold && navbarScrolled) {
        setNavbarScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navbarScrolled]);

  useEffect(() => {
    // Update the isSmallScreen state on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <header className={navbarScrolled ? 'navbar-scrolled' : ''}>
    <div className='navbar'>
     
      <ul className='links' id = "#">
        <li><Link to = '/'>Home</Link></li>
        <li><Link to = '/aboutus'>About Us</Link></li>
        <li><Link to = '/?instagram'>Instagram</Link></li>
        <li><Link to = '/?contact'>Contact Us</Link></li>
        <li><Link to = '/menu'>Menu</Link></li>
        <li><Link to = '/happyhour'>Happy Hour</Link></li>
        
      </ul>
      <div className='toggle_btn' onClick={handleToggleBtn}>
        <i className={!isOpen ? 'fa-solid fa-bars' : 'fa-solid fa-xmark'}></i>
      </div>
    </div>
    <div className={!isOpen? `dropdown_menu` : `dropdown_menu open`}>
      <div className = "xmarkWrapper" onClick={handleToggleBtn}>
        <i className={!isOpen ? 'fa-solid fa-bars' : 'fa-solid fa-xmark'}></i>
      </div>
      <div className = "mobileLiWrapper">
        <li><Link to='/' onClick={closeMenu}>Home</Link></li>
        <li><Link to='/aboutus' onClick={closeMenu}>About Us</Link></li>
        <li><Link to='/?instagram' onClick={closeMenu}>Instagram</Link></li>
        <li><Link to='/?contact' onClick={closeMenu}>Contact</Link></li>
        <li><Link to='/menu' onClick={closeMenu}>Menu</Link></li>
        <li><Link to='/happyhour' onClick={closeMenu}>Happy Hour</Link></li>
      </div>
    </div>
  </header >
  );
};

export default CustomNavbar;
