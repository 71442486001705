export const mainmenuData = [
    {"category": "Main Menu", "subCategory" : "salads", "comment" : "", "special" : false, "menu" : "Soup of the Day", "price" : 8},
    {"category": "Main Menu", "subCategory" : "salads", "comment" : "(Add chicken/garlic prawns $7.00)", "special" : false, "menu" : "Citrus Ceasar Salad", "price" : 11},
    {"category": "Main Menu", "subCategory" : "salads", "comment" : "(Add chicken/garlic prawns $7.00)", "special" : false, "menu" : "Quinoa and Spinach Salad", "price" : 18},
    {"category": "Main Menu", "subCategory" : "salads", "comment" : "", "special" : true, "menu" : "Maple Salmon Salad", "price" : 23},

    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Avocado and Bire club Sandwich", "price" : 19},
    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Pulled Pork Panini", "price" : 18},
    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Beyond Meat Burger", "price" : 18.75},
    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Swiss Mushroom Melt Burger", "price" : 19.25},
    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Avocado Chicken Burger", "price" : 19.25},
    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Roasted Beef Dip", "price" : 18},
    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Classic Beef Burger", "price" : 19.25},
    {"category": "Main Menu", "subCategory" : "Sandwiches & Burgers", "comment" : "", "special" : false, "menu" : "Upgrade : Yam fries & Ceaser Salad", "price" : 6},

    
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Garden Fresh Vegetable Pasta", "price" : 17},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Spaghettini Carbonara", "price" : 19},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Creamy Pesto Chicken Penne Pasta", "price" : 19},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Chicken and Chorizo Penne Pasta", "price" : 20.5},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Spicy Prawns Spaghettini", "price" : 20.5},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Spicach & Feta Pizza", "price" : 17.5},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Hawaian Pizza", "price" : 18.25},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "Coco Pizza", "price" : 19.5},
    {"category": "Main Menu", "subCategory" : "Pastas & Pizzas",  "comment" : "", "special" : false, "menu" : "BBQ Pulled Pork Pizza", "price" : 18.75},
    
    
    {"category": "Main Menu", "subCategory" : "Desserts","special" : false,  "comment" : "", "menu" : "Brownie with Ice Cream", "price" : 10},
    
    {"category": "Main Menu", "subCategory" : "Drinks", "special" : false,  "comment" : "", "menu" : "Coca cola, Sprite, etc", "price" : 4.5},
    {"category": "Main Menu", "subCategory" : "Drinks", "special" : false,  "comment" : "", "menu" : "Juice (Ask server for choice)", "price" : 5},
    {"category": "Main Menu", "subCategory" : "Drinks", "special" : false,  "comment" : "", "menu" : "Perrier Water", "price" : 5.5},
    {"category": "Main Menu", "subCategory" : "Drinks", "special" : false,  "comment" : "", "menu" : "Hot Chocolate", "price" : 5.25},
    {"category": "Main Menu", "subCategory" : "Drinks", "special" : false,  "comment" : "", "menu" : "Coffee", "price" : 4},
    {"category": "Main Menu", "subCategory" : "Drinks", "special" : false,  "comment" : "", "menu" : "Teas (Ask server for choice)", "price" : 4},
    {"category": "Main Menu", "subCategory" : "Drinks", "special" : false,  "comment" : "", "menu" : "Redbull", "price" : 5.25},

    {"category": "Main Menu", "subCategory" : "Mains", "special" : false,  "comment" : "", "menu" : "Marsala Chicken", "price" : 24},
    {"category": "Main Menu", "subCategory" : "Mains", "special" : false,  "comment" : "", "menu" : "Madras Curry Rice Bowl", "price" : 24.5},
    {"category": "Main Menu", "subCategory" : "Mains", "special" : false,  "comment" : "", "menu" : "Beefs Strognoff", "price" : 24.75},
    {"category": "Main Menu", "subCategory" : "Mains", "special" : false,  "comment" : "(Add prawns $7.00)", "menu" : "Wild Blackened Salmon", "price" : 25.5},
    {"category": "Main Menu", "subCategory" : "Mains", "special" : false,  "comment" : "", "menu" : "Steak Peppercorn", "price" : 27.75},
    {"category": "Main Menu", "subCategory" : "Mains", "special" : false,  "comment" : "", "menu" : "Steak & Prawns", "price" : 31.75},

    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "(Minimum order of 10)", "special" : true, "menu" : "Wing Special", "price" : 14.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Hummus", "price" : 10.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Crispy Yam Fries", "price" : 12.75},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Cauliflower Bites", "price" : 14.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "(Add chicken,pulled pork $7.00 / Add beef $7.00)", "special" : false, "menu" : "Poutine", "price" : 18.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Chilli Chicken", "price" : 17},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Spicy Meat Balls", "price" : 17.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Sliders", "price" : 19.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Drunken Mussels and Chorizo", "price" : 18.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Baked Garlic and Cheese Prawns", "price" : 18},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Fish Tacos", "price" : 18.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Fish and Chips", "price" : 18.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Calamari", "price" : 20.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Chickens Strips with Fries", "price" : 18.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "", "special" : false, "menu" : "Chicken Tikka Skewer", "price" : 19.5},
    {"category": "Main Menu", "subCategory" : "Tapas & Appetizers", "comment" : "(Add chicken, pulled pork $7.00 / Add beef $7.00)", "special" : false, "menu" : "Three Chesse Nachos", "price" : 18.5},

]

export const brunchData = [
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Robson St. Breakfast Specials", "price" : 14},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Spinach Tomato Benedict", "price" : 15},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Canadian Benedict", "price" : 16.5},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Coco Rico Benedict", "price" : 17.75},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Smoked Salmon Benedict", "price" : 19},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Garden Fresh Omelette", "price" : 15},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Ham & Cheese Omelette", "price" : 15.5},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Bacon & Cheese Omelette", "price" : 16.75},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Smoked Salmon Omelette", "price" : 19},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Grilled Ham & Cheese Sandwich", "price" : 19},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Belt Sandwich", "price" : 18.5},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Smoked Salmon Panini", "price" : 19.5},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Brunch Burger", "price" : 21},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Cinnamon French Toast", "price" : 16.5},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Buttermilk Pencakes", "price" : 16},
    {"category": "Brunch", "subCategory" : "Brunch", "special" : false, "menu" : "Belgian Waffles", "price" : 16.5},
    
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Teas", "price" : 4},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Regular Coffee", "price" : 4},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Espresso", "price" : 5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Americano", "price" : 5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Mocha", "price" : 5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Cappuccino", "price" : 5.25},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Latte", "price" : 5.25},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Hot Chocolate", "price" : 5.25},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Pop", "price" : 4.5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Juice", "price" : 5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Lemonade", "price" : 5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Grapefruit Juice", "price" : 5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Ice Teas", "price" : 5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Perrier Water", "price" : 5.5},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Redbull", "price" : 5.25},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Mimosa", "price" : 8},
    {"category": "Brunch", "subCategory" : "Drinks", "special" : false, "menu" : "Caesar or Bloody Mary", "price" : 10},
    

    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Extra Toast", "price" : 2.5},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Extra Cheese", "price" : 2},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Extra Egg", "price" : 2.5},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Hashbrowns", "price" : 4},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Chorizo Sausage", "price" : 5},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "English Muffins", "price" : 3.5},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Fruits Salad", "price" : 7},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Bacons (4strips)", "price" : 7},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Breakfast Sausage", "price" : 3.5},
    {"category": "Brunch", "subCategory" : "Extra", "special" : false, "menu" : "Avocado", "price" : 5},
]


export const drinkData = [
    {"category": "Drink", "subCategory" : "Draft Beer", "special" : false, "comment" : "(ALC. 5%)",  "menu" : "Heineken", "sleevePrice" : 6, "pitcherPrice": 21.5},
    {"category": "Drink", "subCategory" : "Draft Beer", "special" : false, "comment" : "(ALC. 6.2%)",  "menu" : "Lagunitas Ipa", "sleevePrice" : 6, "pitcherPrice": 21.5},
    {"category": "Drink", "subCategory" : "Draft Beer", "special" : false, "comment" : "(ALC. 5.4%)",  "menu" : "Belgian Moon", "sleevePrice" : 5.5, "pitcherPrice": 20},
    {"category": "Drink", "subCategory" : "Draft Beer", "special" : false, "comment" : "",  "menu" : "Granville island honey lager", "sleevePrice" : 5.25, "pitcherPrice": 19.5},
    {"category": "Drink", "subCategory" : "Draft Beer", "special" : false, "comment" : "",  "menu" : "Granville island english bay pale ale", "sleevePrice" : 5.25, "pitcherPrice": 19.5},
    {"category": "Drink", "subCategory" : "Draft Beer", "special" : false, "comment" : "",  "menu" : "Granville island pilsner", "sleevePrice" : 5.25, "pitcherPrice": 19.5},

    {"category": "Drink", "subCategory" : "Cider & Coolers", "special" : false, "menu" : "Growers cider (Apple, Peach)", "price" : 7},
    {"category": "Drink", "subCategory" : "Cider & Coolers", "special" : false, "menu" : "Smirnoff ice", "price" : 7},

    
    {"category": "Drink", "subCategory" : "Highballs", "special" : false, "menu" : "1 oz", "price" : 5.5},
    {"category": "Drink", "subCategory" : "Highballs", "special" : false, "menu" : "2 oz", "price" : 9},
    
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Molson canadian", "price" : 5.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Kokanee", "price" : 5.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Budweiser", "price" : 5.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Sleeman honey brown", "price" : 5.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Heineken", "price" : 6.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Becks", "price" : 6.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Stella artois", "price" : 6.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Corona", "price" : 6.50},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Kronenbourg 1661", "price" : 7},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Asahi", "price" : 7},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Guiness", "price" : 7},
    {"category": "Drink", "subCategory" : "Bottles", "special" : false, "menu" : "Kilkenny", "price" : 7},

    {"category": "Drink", "subCategory" : "Wine", "special" : false, "menu" : "Red wine", "1/2 Litre" : 21, "Glass" :8},
    {"category": "Drink", "subCategory" : "Wine", "special" : false, "menu" : "Jackson triggs merlot", "1/2 Litre" : 21, "Glass" :8},
    {"category": "Drink", "subCategory" : "Wine", "special" : false, "menu" : "Jackson triggs cabernet sauvignon", "1/2 Litre" : 21, "Glass" :8},
    {"category": "Drink", "subCategory" : "Wine", "special" : false, "menu" : "White wine", "1/2 Litre" : 21, "Glass" :8},
    {"category": "Drink", "subCategory" : "Wine", "special" : false, "menu" : "Jackson triggs chardonnay", "1/2 Litre" : 21, "Glass" :8},
    {"category": "Drink", "subCategory" : "Wine", "special" : false, "menu" : "Jackson triggs sauvignon blanc", "1/2 Litre" : 21, "Glass" :8},

    {"category": "Drink", "subCategory" : "Non Alcoholic", "special" : false, "menu" : "Heineken (ALC. 0%)", "price" : 4.5},
    
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Mind fuck", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "69er", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "The terminator", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Candy bar", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Bazzoka Joe", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Kamikazi", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Cocaine", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Gladiator", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Superman", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Bear fucker", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Dr Pepper", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Jager bomb", "price" : 6},
    {"category": "Drink", "subCategory" : "Shooters", "special" : false, "menu" : "Panty dropper", "price" : 6},

    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Bloody mary", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Caesar", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Sex on the beach", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Blue hawaiian", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Fuzzy navel", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Banana boat", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Zombie", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Classic daiquiri", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Cuba libre", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Dark & Stormy", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Tequila sunrise", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Texas two step", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Paloma", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Pina colada", "price" : 7},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Margarita", "price" : 7},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Spicy margarita", "price" : 11},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Bellini", "price" : 7},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Mojito", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Old fashion", "price" : 11},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Whiskey sour", "price" : 11},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Singapore sling", "price" : 9},
    {"category": "Drink", "subCategory" : "Cocktails", "special" : false, "menu" : "Whiskey ginger smash", "price" : 9},

    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "007", "price" : 9},
    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "Cosmopolitan", "price" : 9},
    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "Metropolitan", "price" : 9},
    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "Red passion", "price" : 9},
    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "Booty call", "price" : 9},
    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "Play boy", "price" : 9},
    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "Butterfly", "price" : 9},
    {"category": "Drink", "subCategory" : "Martinis", "special" : false, "menu" : "Apple martini", "price" : 9},
]


export const HappyHourData = [
    {"category": "Happy Hour", "subCategory" : "Beer", "special" : false, "menu" : "Draft beer", "price" : 4.25},
    {"category": "Happy Hour", "subCategory" : "Beer", "special" : false, "menu" : "Domestic beer", "price" : 4.5},
    {"category": "Happy Hour", "subCategory" : "Beer", "special" : false, "menu" : "Single highball", "price" : 4.5},
    {"category": "Happy Hour", "subCategory" : "Beer", "special" : false, "menu" : "Double highball", "price" : 7.5},
    {"category": "Happy Hour", "subCategory" : "Beer", "special" : false, "menu" : "Glass of wine", "price" : 6},

    {"category": "Happy Hour", "subCategory" : "Food", "special" : false, "menu" : "Hummus & Pita", "price" : 8},
    {"category": "Happy Hour", "subCategory" : "Food", "special" : false, "menu" : "Wings", "price" : 10},
    {"category": "Happy Hour", "subCategory" : "Food", "special" : false, "menu" : "Spicy meatballs", "price" : 10},
    {"category": "Happy Hour", "subCategory" : "Food", "special" : false, "menu" : "Beef sliders", "price" : 13},
    {"category": "Happy Hour", "subCategory" : "Food", "special" : false, "menu" : "Fish tacos", "price" : 13},
    {"category": "Happy Hour", "subCategory" : "Food", "special" : false, "menu" : "Fish & chips", "price" : 14},
    {"category": "Happy Hour", "subCategory" : "Food", "special" : false, "menu" : "Poutine", "price" : 14},
]
