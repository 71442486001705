import './App.css';
import {Container, Row, Col, Button, Modal} from 'react-bootstrap';
import CustomNavbar from './layouts/CustomNavbar';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FooterComponent from './layouts/FooterComponent';
import Maps from './Components/Maps';
import TimeAgo from './Components/TimeAgo';
import logo from './Images/logo.png';
import Img1 from './Images/gallery/insta1.webp';
import Img2 from './Images/gallery/insta2.webp';
import Img3 from './Images/gallery/insta3.webp';
import Img4 from './Images/gallery/insta4.webp';
import Img5 from './Images/gallery/insta5.webp';
import Img6 from './Images/gallery/insta6.webp';
import Img7 from './Images/gallery/insta7.webp';
import Img8 from './Images/gallery/insta8.webp';
import Img9 from './Images/gallery/insta9.webp';
import Img10 from './Images/gallery/insta10.webp';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);
  const [modalShow, setModalShow] = useState(false);
  // const [curDataIndex, setCurDataIndex] = useState(-1);
  const [curDataIndex] = useState(-1);

  useEffect(()=> {
    if(location.search ==='?aboutus') {
      document.getElementById('aboutus').scrollIntoView({behavior: 'smooth' });
    }else if(location.search === '?contact') {
      document.getElementById('contact').scrollIntoView({behavior: 'smooth' });
    }else if(location.search === '?instagram') {
      document.getElementById('instagram').scrollIntoView({behavior: 'smooth' });
    }else {
      // document.getElementById('top').scrollIntoView({behavior: 'smooth' });
    }
  }, [location])

  useEffect(() => {
    // Update the isSmallScreen state on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // const api_key= process.env.REACT_APP_INSTAGRAM_API_KEY
  // const page_id = process.env.REACT_APP_INSTAGRAM_PAGE_ID
  // const [data, setData] = useState([]);
  const [data] = useState([])
  // useEffect(()=> {
  //     const fetchInstagram = async () => {

  //         const url = `https://graph.facebook.com/v17.0/${page_id}/media?fields=id,media_type,caption,media_url,username,timestamp&access_token=${api_key}`
  //         const res = await fetch(url)
  //         const dataFromInstagramp = await res.json()
  //         setData(dataFromInstagramp.data)
  //     }
  //     fetchInstagram()
  // }, [])
  
  // const handleInstagramModal = (index) => {
  //   // window.location("http://instagram.com/_u/{USERNAME}/")
  //   setModalShow(true)
  //   setCurDataIndex(index)

  // }
  const navigateMenu = () => {
    navigate('/menu')
  }
  const navigateHappyHourMenu = () => {
    navigate('/happyhour')
  }

  const images = [
    Img1,Img2,Img3,Img4,Img5,Img6,Img7,Img8,Img9,Img10
  ];
  return (
    <>
    <div className='wrap'>
      <div className="main" id = "top">
        <CustomNavbar></CustomNavbar>
            <div className= "bannerWrapper">
              <div>
                <img style = {{width:isSmallScreen ? "70%" : "40%"}} alt='logo' src= {logo}></img>
              </div>
              {/* <div className = "banner">COCO RICO Cafe</div>
              <div className = "mainTitle">Bistro & Tapas Bar</div> */}
              <Button className = 'mainBtn smBtn' variant="light" onClick = {navigateMenu}>View Menu</Button>
            </div>
      </div>

      <div className="happyhour" id = "top">
        <Container className='align-items-center happyhourWrapper' style = {{background: "transparent"}}>
          <Row style = {{height : "100%"}}>
            <Col xs = {{span : 12}} lg = {{span : 6}} className='align-items-center'>
              <div className = "contentsWrapper">
                <div className="rectangle"></div>
                <div className= 'subtitle'>Happy Hour</div>
                <div className= 'contents happyhourContents'>

                  {!isSmallScreen ?  "Don't miss out on this incredible Happy Hour experience! Gather your friends, family, or colleagues and head on over to Coco Rico Café . Let the good times roll from 2:00pm to 6:00pm. See you there!" : "Gather your friends, family, or colleagues and head on over to Coco Rico Cafe. Let the good times roll from 2:00pm to 6:00pm."}</div>

                <Button className = 'mainBtn happyHourBtn' onClick = {navigateHappyHourMenu} variant="light">View Happy Hour Menu</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>



      <div className = 'aboutus' id='aboutus'>
      <Container className='align-items-center aboutUsWrapper' style = {{background: "transparent"}}>
          <Row>
            <Col  xs = {{span : 12, offset : 0}} lg ={{span : 6, offset : 6}} className='align-items-center'>
              <div className = "aboutusContentsWrapper">
                <div className="rectangle"></div>
                <div className= 'subtitle'>About Us</div>
                <div className= 'contents aboutusContents'>
                  {
                    !isSmallScreen ? (
                      <>
                        <div className= 'contents aboutusContents'>Welcome to the Coco Rico Café, a beloved local hotspot located on Robson Street in the heart of downtown Vancouver. With a lively atmosphere and a dedicated community of regulars, we offer a warm and welcoming environment for everyone. </div>
                        <div className= 'contents aboutusContents'>Join us to watch hockey games on our large TVs while enjoying high-quality cocktails expertly crafted by our talented bartenders. Have an unforgettable experience that embodies the spirit of Vancouver's downtown pub scene.</div>
                      </>
                    ) : 
                    "Welcome to the Coco rico cafe, a beloved local hotspot located on Robson Street in downtown Vancouver. Have an unforgettable experience in"
                  }
                  
                </div>
              
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      
      <div className = 'instagram' id='instagram'>
        <Container  className='align-items-center' >
          <Row>
            <Col xs = {12} lg = {12} style= {{textAlign:"center"}} className='align-items-center'>
              <div>
                <div className="rectangle instaRectangle"></div>
                <div className = "subtitle">Instagram</div>
              </div>
                
            </Col>
            <Col xs = {12}>
              <div className= 'contents instaContents' style = {{textAlign: "center"}}>Follow @cocoricocafe on Instagram for more information</div>
            </Col>
          </Row>
          <Row style ={{marginTop : "40px", margin: "auto"}}>
            {images.map((img, index) => (
              <Col
                key={index}
                xs={6} 
                md={6}
                lg={2}
                className="mb-4 custom-col"
              >
                <img src={img} alt={`img-${index}`} style={{ width: '100%', height: 'auto' }} />
              </Col>
            ))}
            {/* Changed Instagram connection to Image Insertion */}
            {/* {
              data&& data.slice(0,4).map((item, index) => {
                return (<Col key = {index} lg = {3} xs = {6} style= {{textAlign:"center"}}>
                  <div onClick = {() => handleInstagramModal(index)} style = {{height :"100%"}}>
                    <img className = "instaPics" style = {{width : "100%", height : "80%", marginBottom: "15px"}} src={item.media_url} alt ={item.id}/>
                  </div>
                </Col>)
              })
            } */}
          </Row>
          <Row>
            <Col style= {{textAlign:"center", marginTop : "40px"}}>
              <Button className = 'mainBtn instaBtn' variant="light" onClick = {() => {window.open("https://www.instagram.com/cocoricocafe/", "_blank")}}>Visit Instagram</Button>
            </Col>
          </Row>
        </Container>
      </div>

      
      <div className = 'contactus' id='contact'>
      <Container className='align-items-center' id = 'contact'>
          <Row>
            <Col lg = {12} style= {{textAlign:"center"}}>
              
                <div className="rectangle" style = {{display:"inline-block"}}></div>
                <div className = "subtitle" style = {{textAlign: "center"}}>Contact Us</div>
            </Col>
            <Col lg = {12}>
              <div className= 'contents contactusContents' style = {{textAlign: "center"}}>If you have any questions for us,<br/>
              please contact us by the following contacts</div>
            </Col>
          </Row>
          {
                    !isSmallScreen ? (
                      <>
                     <Row className = "contacUsInfoRow">
            <Col  xs = {12} lg = {{offset : 1, span:6}} style= {{textAlign:"center"}}>
              {/* <img className = "contactUsMap" src={map} alt ="map"/> */}
              <Maps></Maps>
            
            </Col>
            <Col className = "contactUsInfoWrapper" xs = {{offset:1, span:2}} lg = {{offset: 0, span: 1}}>  
              <i className = "contactUsIcon" class="fa-solid fa-phone" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>
              <i className = "contactUsIcon" class="fa-solid fa-envelope" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>
              <i className = "contactUsIcon" class="fa-solid fa-location-dot" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>
              <i className = "contactUsIcon" class="fa-solid fa-clock" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>
            </Col>

            <Col className = "contactUsInfoWrapper" xs = {{offset: 0, span:9}} lg = {4}>
              <div className = "contactUsInfoTable" >
                <div className ="contents contactUsInfoText">
                  (604) 687 - 0424
                  </div>
              </div>
              
            
              <div className = "contactUsInfoTable" >
                <div className ="contents contactUsInfoText" >
              info@cocoricocafe.ca
              </div>
              </div>
              
              <div className = "contactUsInfoTable" >
                <div className ="contents contactUsInfoText">
              1290 Robson St, Vancouver<br/>      BC, Canada, V6E1C1
              </div>
              </div>
              
              <div className = "contactUsInfoTable" >
                <div style = {{display: "table-cell", verticalAlign: "middle"}}>
                <div className ="contents contactUsInfoText" style = {{display : "flex", flexDirection: "row", marginTop : "0px", marginBottom: "0px"}}>
                  <div style = {{marginRight :"20px"}}>
                  Sun ~ Thu <br/>
                  Fri, Sat
                  </div>
                  <div>
                  8:00am - 1:00am<br/> 
                  8:00am - 2:00am
                  </div>
                </div>
                </div>
              </div>
              
            </Col>
          </Row>
          </>
                    ) : 
                    (
                      
                      <>
                        <Row>
                          <Col  xs = {12} lg = {{offset : 1, span:6}} style= {{textAlign:"center", padding : "30px"}}>
                            <Maps/>
                          </Col>
                        </Row>
                        <Row style= {{marginTop : "25px", marginBottom:"10px"}}> 
                          <Col xs = {{offset:2, span:1}}>  
                          <i class="fa-solid fa-phone" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>        
                          </Col>
                          <Col xs = {9}>
                          <div style ={{color: "white", display: "inline"}}>    (604) 687 - 0424</div>
                          </Col>
                        </Row>
                        <Row style= {{marginTop : "10px", marginBottom:"10px"}}> 
                          <Col xs = {{offset:2, span:1}}>  
                          <i class="fa-solid fa-envelope" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>        
                          </Col>
                          <Col xs = {9}>
                          <div style ={{color: "white", display: "inline"}}>info@cocoricocafe.ca</div>
                          </Col>
                        </Row>
                        <Row style= {{marginTop : "10px", marginBottom:"10px"}}> 
                          <Col xs = {{offset:2, span:1}}>  
                          <i class="fa-solid fa-location-dot" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>        
                          </Col>
                          <Col xs = {9}>
                          <div style ={{color: "white", display: "inline"}}>1290 Robson St, Vancouver<br/>      BC, Canada, V6E1C1</div>
                          </Col>
                        </Row>
                        <Row style= {{marginTop : "10px"}}> 
                          <Col xs = {{offset:2, span:1}}>  
                          <i class="fa-solid fa-clock" style = {{marginRight : "30px", margin: "auto", color : "white", fontSize : "20px"}}/>        
                          </Col>
                          <Col xs = {9}>
                          <div style ={{color: "white", display: "inline"}}> 
                          <div style = {{display : "flex", flexDirection : "row"}}>

                          <div style = {{marginRight :"20px"}}>
                            Sun ~ Thu <br/>
                            Fri, Sat
                            </div>
                            <div>
                            8:00am - 1:00am<br/> 
                            8:00am - 2:00am
                            </div>
                          </div>
                          
                          </div>
                          </Col>
                        </Row>
           </>

                    )
                  }
          
          
        </Container>
      </div>
    </div>

    <FooterComponent type = "1"/>
    
    <Modal
      show = {modalShow}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      
      centered
      style = {{paddingLeft: "0px !important" }}
      >
      {
        curDataIndex!==-1 && (
          
            <Modal.Body style = {{width : "100%", height : "80%"}}>
              <Row >
                <Col xs = {12} lg = {6}>
                  <img className = "instaPics" style = {{width : "100%", marginBottom: "15px"}} src={data[curDataIndex].media_url} alt ={data[curDataIndex].id}/>
                </Col>
                <Col xs = {12} lg = {6}>
                  {/* <h2>{data[curDataIndex].username}</h2> */}
                  <h3>COCORICO CAFE</h3>
                  <TimeAgo timestamp={data[curDataIndex].timestamp} />
                  <hr/>
                  <p style={{whiteSpace: "pre-wrap"}}>
                    {data[curDataIndex].caption}
                  </p>
                </Col>
              </Row>
            </Modal.Body>
          )
        
        
      }
      
      <Modal.Footer>
        <Button onClick={() => setModalShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default App;
