import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { HappyHourData } from '../data/mainmenuData'
import './happyhour.css'

const HappyHourComponent = () => {
  return (
    <Container className ="haapyHourContainer">
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle'>Beer</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            HappyHourData.map((item) =>  {
                if(item.subCategory === "Beer") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col  xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>        
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        
        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle mgTop60'>Drinks</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            HappyHourData.map((item) =>  {
                if(item.subCategory === "Food") {
                return <>
                    <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col  xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                        <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>    
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                }
            })
        }

        <div style ={{marginBottom : "10vh"}}/>
        
    </Container>
  )
}

export default HappyHourComponent