import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { mainmenuData } from '../data/mainmenuData'
import './menu.css'
const MainmenuComponent = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500);
    useEffect(() => {
        // Update the isSmallScreen state on window resize
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 500);
        };
    
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return (
    <Container style ={{backgroundColor : "white", width :"100%", height : "100%", marginTop : "60px"}}>
        
        <Row>
            <Col xs = {{span:10}} lg = {{offset : 1, span : 5}}>
                <div className = 'generalMenuTitle'>Salads</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            mainmenuData.map((item) =>  {
                if(item.subCategory === "salads") {
                    if (item.special === true) {
                        if (!isSmallScreen) {
                            return <>
                                <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                    <Col lg = {{offset : 1, span : 3}}>
                                        <div className = 'singleMenuTitle' style = {{fontWeight : "bold"}}>
                                            {item.menu}
                                        </div>
                                    
                                    </Col>
                                    <Col lg = {{span : 3}}>
                                        <div style = {{marginLeft : "0px", paddingLeft : "10px", paddingRight : "10px", display: "inline-block", backgroundColor: "black", color : "white"}}>COCO SPECIAL</div>
                                    </Col>
                                    
                                    <Col lg = {{offset : 3, span : 1}} style = {{textAlign : "right"}}>
                                        <div  className = 'singleMenuTitle' >
                                            {`$${item.price.toFixed(2)}`}    
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }else {
                            return <>
                                <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                    <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                        <div className = 'singleMenuTitle' style = {{fontWeight : "bold"}}>
                                            {item.menu}
                                        </div>
                                    
                                    </Col>
                                    <Col xs = {{span : 3}} lg = {{offset : 3, span : 1}} style = {{textAlign : "right"}}>
                                        <div className = 'singleMenuTitle'>
                                            {`$${item.price.toFixed(2)}`}    
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        
                    }else {
                        if (!isSmallScreen) {
                            return <>
                            <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                    <div className = 'singleMenuTitle'>
                                        {item.menu} {item.comment}
                                    </div>
                                </Col>
                                <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                    <div className = 'singleMenuTitle'>
                                    {`$${item.price.toFixed(2)}`}    
                                    </div>
                                    
                                </Col>
                            </Row>
                        </>
                        }else {
                            return <>
                            <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                    <div className = 'singleMenuTitle'>
                                        {item.menu}
                                    </div>
                                </Col>
                                <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                    <div className = 'singleMenuTitle'>
                                    {`$${item.price.toFixed(2)}`}    
                                    </div>
                                </Col>
                                <Col>
                                <div className = 'comment'>
                                        {item.comment}
                                    </div>
                                </Col>
                            </Row>
                        </>
                        }
                        
                    }
                
                }else {
                    return null;
                } 
            })
        }

        <Row>
            <Col lg = {{offset : 1, span : 5}}>
                <div  className = 'generalMenuTitle mgTop60'>Sandwiches & Burgers</div>
            </Col>
            <Col lg = {{span : 5}} style = {{display: "flex"}}>
                <div  className = 'subMenuTitle'>Served with fries / house salad</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            mainmenuData.map((item) =>  {
                if(item.subCategory === "Sandwiches & Burgers") {
                
                    if (!isSmallScreen) {
                        return <>
                        <Row key = {item.menu} style = {{marginTop : "15px"}}>
                            <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                <div className = 'singleMenuTitle'>
                                    {item.menu} {item.comment}
                                </div>
                            </Col>
                            <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                {`$${item.price.toFixed(2)}`}    
                                </div>
                                
                            </Col>
                        </Row>
                    </>
                    }else {
                        return <>
                        <Row key = {item.menu} style = {{marginTop : "15px"}}>
                            <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                <div className = 'singleMenuTitle'>
                                    {item.menu}
                                </div>
                            </Col>
                            <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                {`$${item.price.toFixed(2)}`}    
                                </div>
                            </Col>
                            <Col>
                            <div className = 'comment'>
                                    {item.comment}
                                </div>
                            </Col>
                        </Row>
                    </>
                    }
                }else {
                    return null;
                } 
            })
        }

        <Row>
            <Col lg = {{offset : 1, span : 5}}>
            <div  className = 'generalMenuTitle mgTop60'>Pastas & Pizzas</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            mainmenuData.map((item) =>  {
                if(item.subCategory === "Pastas & Pizzas") {
                return <> 
                <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                            <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>   
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                } 
            })
        }

        <Row>
            <Col lg = {{offset : 1, span : 5}}>
            <div  className = 'generalMenuTitle mgTop60'>Desserts</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            mainmenuData.map((item) =>  {
                if(item.subCategory === "Desserts") {
                return <> 
                <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                            <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>   
                        </Col>
                    </Row>
                </>
               }else {
                return null;
            } 
            })
        }
        
        <Row>
            <Col lg = {{offset : 1, span : 5}}>
            <div  className = 'generalMenuTitle mgTop60'>Drinks</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            mainmenuData.map((item) =>  {
                if(item.subCategory === "Drinks") {
                return <> 
                <Row key = {item.menu} style = {{marginTop : "15px"}}>
                        <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                            <div className = 'singleMenuTitle'>
                                {item.menu}
                            </div>
                        </Col>
                        <Col xs = {{span: 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                            <div className = 'singleMenuTitle'>
                            {`$${item.price.toFixed(2)}`}    
                            </div>   
                        </Col>
                    </Row>
                </>
                }else {
                    return null;
                }
            })
        }

        <Row>
            <Col lg = {{offset : 1, span : 5}}>
            <div  className = 'generalMenuTitle mgTop60'>Mains</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            mainmenuData.map((item) =>  {
                if(item.subCategory === "Mains") {
                if (!isSmallScreen) {
                        return <>
                        <Row key = {item.menu} style = {{marginTop : "15px"}}>
                            <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                <div className = 'singleMenuTitle'>
                                    {item.menu} {item.comment}
                                </div>
                            </Col>
                            <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                {`$${item.price.toFixed(2)}`}    
                                </div>
                                
                            </Col>
                        </Row>
                    </>
                    }else {
                        return <>
                        <Row key = {item.menu} style = {{marginTop : "15px"}}>
                            <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                <div className = 'singleMenuTitle'>
                                    {item.menu}
                                </div>
                            </Col>
                            <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                <div className = 'singleMenuTitle'>
                                {`$${item.price.toFixed(2)}`}    
                                </div>
                            </Col>
                            <Col>
                            <div className = 'comment'>
                                    {item.comment}
                                </div>
                            </Col>
                        </Row>
                    </>
                    }
                }else {
                    return null;
                }
            })
        }

        <Row>
            <Col lg = {{offset : 1, span : 5}}>
            <div  className = 'generalMenuTitle mgTop60'>Tapas & Appetizers</div>
            </Col>
            <Col lg = {{offset : 1, span : 10}}>
                <hr style = {{width : "100%"}}/>
            </Col>
            
        </Row>
        {
            mainmenuData.map((item) =>  {
                if(item.subCategory === "Tapas & Appetizers") {
                    if (item.special === true) {
                        if (!isSmallScreen) {
                            return <>
                                <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                    <Col lg = {{offset : 1, span : 2}}>
                                        <div  className = 'singleMenuTitle' style = {{fontWeight : "bold"}}>
                                            {item.menu}
                                        </div>
                                    
                                    </Col>
                                    <Col lg = {{span : 4}}>
                                        <div style = {{paddingLeft : "10px", paddingRight : "10px", display: "inline-block", backgroundColor: "black", color : "white"}}>COCO SPECIAL</div>
                                    </Col>
                                    
                                    <Col lg = {{offset : 3, span : 1}} style = {{textAlign : "right"}}>
                                    <div className = 'singleMenuTitle'>
                                        {`$${item.price.toFixed(2)}`}    
                                    </div>   
                                    </Col>
                                </Row>
                            </>
                        }else {
                            return (<>
                                     <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                    <Col xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                        <div  className = 'singleMenuTitle' style = {{fontWeight : "bold"}}>
                                            {item.menu}
                                        </div>
                                    
                                    </Col>
                                    <Col xs = {{span : 3}} lg = {{offset : 3, span : 1}} style = {{textAlign : "right"}}>
                                        <div>
                                            {`$${item.price.toFixed(2)}`}    
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        
                        )}
                    }else {
                        if (!isSmallScreen) {
                            return <>
                            <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                    <div className = 'singleMenuTitle'>
                                        {item.menu} {item.comment}
                                    </div>
                                </Col>
                                <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                    <div className = 'singleMenuTitle'>
                                    {`$${item.price.toFixed(2)}`}    
                                    </div>
                                    
                                </Col>
                            </Row>
                        </>
                        }else {
                            return <>
                            <Row key = {item.menu} style = {{marginTop : "15px"}}>
                                <Col  xs = {{span: 9}} lg = {{offset : 1, span : 9}}>
                                    <div className = 'singleMenuTitle'>
                                        {item.menu}
                                    </div>
                                </Col>
                                <Col xs = {{span : 3}} lg = {{span : 1}} style = {{textAlign : "right"}}>
                                    <div className = 'singleMenuTitle'>
                                    {`$${item.price.toFixed(2)}`}    
                                    </div>
                                </Col>
                                <Col xs = {{span: 9}}>
                                <div className = 'comment'>
                                        {item.comment}
                                    </div>
                                </Col>
                            </Row>
                        </>
                        }
                    }
              
                }else {
                    return null;
                } 
            })
        }
    </Container>
  )
}

export default MainmenuComponent